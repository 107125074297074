import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

export const ColumnChart = (props) => {
  let subset = [];
  let final = [];
  if (props) {
    if (props.type === "Pricing") {
      for (let i in props.data) {
        subset.push(props.data[i]["year"]);
        subset.push(props.data[i]["payout"]);
        final.push(subset);
        subset = [];
      }
    } else if (props.type === "Data") {
      for (let i in props.data) {
        subset.push(props.data[i]["date"]);
        subset.push(props.data[i]["rainfall"]);
        final.push(subset);
        subset = [];
      }
    }
  }

  const options = {
    chart: {
      alignTicks: false,
    },

    rangeSelector: {
      inputEnabled: false,
    },

    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: props.type === "Data" ? "Rainfall (mm)" : "Payout (%)",
        style: {
          color: Highcharts.getOptions().colors[1],
        },
      },
    },
    exporting: {
      enabled: true,
      filename: props.state + props.name + "_Rainfall",
    },
    series: [
      {
        type: "column",
        name: props.type === "Data" ? "Rainfall" : "Payout (%)",
        data: final,
        color: "#0039E6",
      },
    ],
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
    />
  );
};
